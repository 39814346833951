@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');

/* WP */
.has-text-align-center{
    text-align: center;
}

body{
    font-family: 'Open Sans', sans-serif;
    
}

h1, h2, h3{
    font-family: 'Nunito', sans-serif;
}
h1{
    text-align: center;
}


.main{
    width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
.main_logo{
    width: 100px;
    transition: width 250ms ease-in-out, padding-right 250ms ease-in-out;
}
.main_header {
	height: 120px;
	background-color: white;
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 10;
}
.main_project .main_header {
	position: sticky;
}
.main_header .container, .main_header .container .row{
    height: 100%;
}
.main_header .container .row > div{
    display: flex;
    align-items: center;
}
.main_menu{
    width: 100%;
}
.is-sticky {
    position: sticky;
    display: block;
    top: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
   /* background-color: #ffffff !important;*/
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 80px;
    
  }
.is-sticky .main_logo{
   padding-right: 40px;
  
}

.social_menu{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.social_icon{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: orangered;
    text-align: center;
    font-size: 14px;
    line-height: 30px;    
    color: white;
    margin-left: 8px;
}
.social_icon a{
    color: white;
    
}
.social_icon:hover{
    background-color: orangered;
    
}
.main_menu_routeur{
    width: 100%;
}
.main_menu ul, .main_menu_routeur ul{
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    padding-left: 0px;
    width: 100%;
    justify-content: space-between;
}
.main_menu ul li, .main_menu_routeur ul li{
    font-size: 16px;
   
}
.main_menu ul li a{
    cursor: pointer;
    /*position: relative;
    display: inline-block;
    
    color: orangered;
    overflow: hidden;
    background: linear-gradient(to right, black, black 50%, orangered 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 0%;
    transition: background-position 275ms ease;*/
}
.menu_active{
    color: orangered;
    overflow: hidden;
    background: linear-gradient(to right, black, black 50%, orangered 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 0%;
    transition: background-position 275ms ease;
}
.menu_active_down{
   
  background-position: -100% 100% !important;
}
.menu_active_up{
   background-position: 100% 100% !important;
}

.menu_anim_home.menu_active_down{
    color: orangered;
    overflow: hidden;
    background: linear-gradient(to right, black, black 50%, orangered 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 0%;
    transition: background-position 275ms ease;
}
.main_menu_routeur ul li a{
    text-decoration: none;
    color: black;
}
.menu_routeur_active{
    color: orangered !important;
}
.mobile_menu_container .menu_routeur_active{
    color: white !important;
}
.social_menu{
    justify-content: flex-end;
}

/* Mobile */
.burger_container{
    justify-content: right;
}
.burger_button{
   
    background-color: transparent;
    -webkit-appearance: none;
    border: none;
    outline: none;

    cursor: pointer;
    transition: all 175ms ease-in-out;
}
.burger_button svg{
    fill: red;
    width: 35px;
}
.burger_button_close {
	position: absolute;
	top: 35px;
	right: 100px;
}
.burger_button_close svg{
    fill: white;
    width: 35px;
    position: absolute;
}
.header_menu .header_social{
    
}

.mobile_menu_container {
	background-color: orange;
    background-position-x: -100%;
	position: fixed;
	z-index: 8;
	top: 0px;
	
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

@media screen and (max-height: 500px) {
    .mobile_menu_container {
        justify-content: space-between;
    }
}
.mobile_menu_container .main_menu, .mobile_menu_container .main_menu_routeur{
  width: auto;
}
.mobile_menu_container .main_menu ul, .mobile_menu_container .main_menu_routeur ul {
    flex-direction: column;
    width: auto;
}
.mobile_menu_container .main_menu ul li, .mobile_menu_container .main_menu_routeur ul li {
    text-align: center;
    color: white;
    font-size: 30px;
}

.mobile_menu_container .main_menu ul li .menu_active{
    color: white!important;
    background-color: white;
    background-image: none;
    
}
.mobile_menu_container .main_menu_routeur ul li a{
    color: white;
}
.mobile_menu_container .social_icon{
    background-color: white;
}
.mobile_menu_container .social_icon a, .mobile_menu_container .social_icon div{
    
    color: orangered;
}
.home{
    position: relative;
}
.main_anim_home{
    height: 100vh;
    overflow-x: hidden;
   
}
/* AGENCE */

.main_agence{
    height: 100vh;
    padding-top: 0px;
    padding-bottom: 150px;;
    background-color: orangered;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .main_agence{
        height: 120vh;
     
    }
}
@media screen and (max-height: 700px) {
    .main_agence{
        height: 140vh;
        
        
    }
}
@media screen and (max-height: 600px) {
    .main_agence{
        height: 150vh;
        align-items: flex-start;
    }
}
@media screen and (max-height: 500px) {
    .main_agence{
        height: 190vh;
    }
}
@media screen and (max-height: 400px) {
    .main_agence{
        height: 210vh;
    }
}
.agence_container{
   
    
}
.main_agence h1{
    text-align: center;
}
.agence_citation{
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;

}
.agence_citation_auteur{
    text-align: center;
    font-style: italic;
}
/* */

.main_expertises {
	min-height: 100vh;
	/* height: 100vh; */
	padding-top: 0px;
    padding-bottom: 150px;
	/* background-color: orange; */
	display: flex;
	align-items: center;
}
@media screen and (max-height: 600px) {
    .main_expertises {
        min-height: 150vh;
        align-items: flex-start;
    }
}
.main_expertises h1{
	text-align: center;
}
#expertises .col-12 p{
    text-align: center;
}
.main_projets{
    height: auto;
    background-color: orangered;
    padding-top: 64px;
    padding-bottom: 64px;
}
.main_projets h1{
    text-align: center;
    color: white;
    margin-bottom: 32px;;
}
.main_apropos{
   min-height: 100vh;
    background-color: #41494c;
    padding-top: 0px;
    padding-bottom: 150px;
    color: white;
    display: flex;
    align-items: center;
}
@media screen and (max-height: 500px) {
    .main_apropos{
        min-height: 110vh;
        align-items: flex-start;
    }
}
#apropos_content .col-4.col-sm-4.col-md-4{
    text-align: center;
}
#apropos_content svg{
    max-width: 70px;
    fill: orangered !important;
}

.main_contact {
	min-height: 100vh;
	padding-top: 0px;
    padding-bottom: 150px;
	display: flex;
	align-items: center;
}

@media screen and (max-height: 600px) {
    .main_contact {
        min-height: 150vh;

    }
}
@media screen and (max-height: 500px) {
    .main_contact {
        min-height: 190vh;

    }
}
.contact_form label {
    padding-bottom: 8px;
}
.contact_form select {
	width: 100%;
	border-radius: 0px;
	background-color: white;
	border: 1px solid orangered;
	height: 30px;
}
.contact_form input{
    width: 100%;
    border-radius: 0px;
	background-color: white;
	border: 1px solid orangered;
	height: 30px;
}
.contact_form textarea{
    width: 100%;
    height: 130px;
    border-radius: 0px;
	background-color: white;
	border: 1px solid orangered;
	
}
.contact_form label{
    display: block;
}
.contact_form label{
    display: block;
}
.contact_form span{
    display: block;
    color: orangered;
}
.contact_right div, .contact_left div {
	width: 100%;
	margin-bottom: 8px;
}
#rgp{
    display: flex;
}
#acceptedTerms{
    width: auto;
    border-radius: 0px;
	background-color: white;
	border: 1px solid orangered;
 
}
#acceptedTermsLabel {
	width: auto;
	margin-left: 16px;
	padding-top: 7px;
}

.captcha_container svg{
    color: #e6332a;
    font-size: 22px;
}

#scroll_home{
    position: fixed;
    bottom: -50px;
    right: 32px;
    width: 40px;
    height: 40px;
    border: 2px solid red;
    border-radius: 8px;
    opacity: 0.8;
    color: red;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    overflow: hidden;
}
.project_item{
    margin-bottom: 16px;
    
}
.project_item a{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: orangered;
    align-items: center;
    
}
.project_item a img{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}
.project_item .project_item_name{
    position: absolute;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: white;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.2s;

}
.project_item a:hover > div{
    display: block;
    color: white;
    opacity: 1;


}
@media screen and (max-width: 992px) {
    .project_item a{
        display: flex;
        flex-direction: column;
        text-decoration: none;
        
    }
    .project_item .project_item_name{
        position: relative;
        opacity: 1;
        padding-top: 6px;
    }
}
.projects_list_img{
    width: 100%;
    height: auto;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    

}
.project_item a:hover .projects_list_img{
    opacity: 0.2;
}
.project_nav{
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
}
.project_nav_prev .button, .project_nav_next .button, .project_back .button{
    width: 100%;
}
@media screen and (max-width: 576px) {
    .project_back, .project_nav_prev, .project_nav_next{
     
        margin-bottom: 16px;
    }
    .button_label{
        display: none;
    }
   
}
.project_img_thumb{
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 10px;
}
.lien_container{
    margin-top: 48px;
}
.lien_container a{
    color: orangered;
}
#project_item a, #project_item a:visited, #project_item a:hover, #project_item a:visited {
    color: orangered;
}
.button{
    border: none;;
    padding: 12px 24px;
    color: white;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    font-weight: 600;
    display: inline-flex;
    cursor: pointer;
    justify-content: center;

}
.button .button_icon_spacer{
    display: inline-block;
    width: 5px;
}
.button_orange{
    background: orangered;
    color: white!important;
}
#project_item .pswp__bg {
	background: orangered;
}
#project_item  .pswp__ui--fit .pswp__top-bar, #project_item  .pswp__ui--fit .pswp__caption {
	background-color: unset;
}
#loading_container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loading{
    width: 150px;
    height: 150px;
    background-image: url(./img/logo-o3digital.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.main_project_container{
    min-height: calc(100vh - 200px);
    margin-bottom: 32px;
}
#main_project_image{
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}



.testimonials_container{
    margin-top: 60px;
}
.testimonials_container h2{
    text-align: center;
} 
.slide{
    display: flex;
    justify-content: center;
}
.slide_inner{
    color: white;
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

}
.slider_texte p:first-child, .slider_texte p:last-child{
    display: inline-block;
    

}
.slider_texte{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.slider_texte p::before {
   content: "“";
   font-size: 2em;
   font-weight: bold;
   padding-right: 5px;
}
.slider_texte p::after {
    content: "”";

   font-size: 2em;
   font-weight: bold;
   padding-left: 5px;
}
.slider_signature{
    text-align: right;
}



.main_footer{
    min-height: 80px;
    background-color: orangered;
    color: white;
   
}
.footer_left{
    text-align: left;
    line-height: 80px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: left;
    
}

.footer_left svg{
    height: 60px;
}
.footer_left span{
    margin-left: 16px;
    font-size: 12px;
}
.footer_left svg path, .footer_left svg rect{
    fill: white;
}
.footer_right{
    text-align: right;
}

.footer_right a{
    padding-left: 16px;
    line-height: 80px;
    color: white;
    text-decoration: none;
}
.footer_right a:hover{
    text-decoration: underline;
}
@media screen and (max-width: 768px) {
    .footer_right{
        text-align: center;
    }
    .footer_left{
        padding-top: 10px;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        
    }
    .footer_left span{
        line-height: 20px;
        margin-left: 0;
    }
    .footer_right a{
        line-height: 40px;
    }
}

/* Anim HOME */
#playground {
   
    padding: 0px;
    position: relative;
    overflow: hidden;
       
  }
  #mySvg2{
    /*display: none;*/
    position: absolute
  }
  #mySvg {
    opacity: 0;
    display:block;
    overflow: visible;
   
  }
  #o3logo {
    visibility: hidden;
    width:200px;
  }
  #o3text {
    opacity: 0;
  }
  #baseLine{
    opacity: 0;
    position: absolute;
   
    display: flex;
    flex-wrap: nowrap;
    
  }
  #baseLineSep {
	background-color: #e6332a;
	width: 5px;
	opacity: 0;
	margin-top: 5px;
	margin-bottom: 2px;
}
  #baseLineTextContainer {
	margin-left: 20px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	
	font-size: 22px;
	line-height: 26px;
	position: relative;
	width: 250px;
	height: 52px;
	color: #e6332a;
	overflow: hidden;
}
  #baseLineTextContainer > div{
    position: absolute;
  }

#contact .social_menu{
    justify-content: center;
    margin-bottom: 40px;
}
.message_sent p{
    color: green;
    font-weight: 500;
    text-align: center;
}
#maps{
    height: 100vh;
}
.testimonial{
    margin-top: 32px;;
}
.testimonial_text{
    text-align: center;
    font-weight: 500;
}
.testimonial_signature{
    text-align: right;
    font-style: italic;
}
.testimonial_text p::before {
	content: "“";
	font-size: 2em;
	font-weight: bold;
	padding-right: 5px;
}
.testimonial_text p::after {
	content: "”";
	font-size: 2em;
	font-weight: bold;
	padding-left: 5px;
}
.rgpd_container {
	position: fixed;
	top: calc(100vh - 50px);
	height: 50px;
	background-color: rgba(65, 73, 76, 0.8);
	width: 100%;
	text-align: center;
	line-height: 50px;
	color: white;
	font-weight: bold;
	font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media screen and (max-width: 500px) {
    .rgpd_container {
        position: fixed;
        top: calc(100vh - 100px);
        height: 100px;
    }
}
@media screen and (max-width: 400px) {
    .rgpd_container {
        position: fixed;
        top: calc(100vh - 110px);
        height: 110px;
        
    }
    .rgpd_container div{
        width: 100%;
        line-height: 30px;
    }
}
.rgpd_container button {
	background-color: orangered;
	color: white;
	border: none;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 8px;
	padding-top: 8px;
	font-weight: bold;
	margin-left: 16px;
	font-size: 14px;
    cursor: pointer;
}
.pages .main_header{
    position: sticky;
}
.page_404  {
	height: calc(100vh - 200px);
}
.page_404 svg {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 64px;
	margin-bottom: 32px;
	width: 100%;
}
.page_404 .pages_container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.page_404 p{
    text-align: center;
    font-weight: 600;
}
.page_404 p a{
    color: orangered;
    text-decoration: none;
}
.overlay_qrcode {
	background-color: orangered;
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100vh;
	z-index: 1000;

}
.overlay_qrcode > div {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
    flex-wrap: wrap;
}
.overlay_qrcode >  svg{
	width: 100%;
	
}
.qr_code_close {
	position: absolute;
	top: 35px;
	right: 100px;
}
.qr_code_close svg {
	fill: white;
	width: 35px;
	position: absolute;
}
.overlay_qrcode p{
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 32px;
}
#header_qr, .qr_code_close{
    cursor: pointer;
}
.page_content{
    margin-bottom: 64px;
}
.chevron_scroll_home svg path{
    fill: #e6332a;
    
}

.chevron_scroll_home svg :hover{
    fill: orangered;
    
}
.chevron_scroll_home  {
        width: 100%;
        font-size: 70px;
        text-align: center;
        position: absolute;
        top: 80vh;
        cursor: pointer;
       
}
@media screen and (max-width: 500px) {
    .chevron_scroll_home  {
        top: 75vh;
       
    }
}